const laudos = {
  ambiente: {
    phrase: 'A culpa é minha e eu coloco onde eu quiser',
    abstract:
      'O nível de crença de Ambiente define as crenças sobre dinheiro relacionadas ao meio externo. São crenças que buscam explicar a situação ou realidade financeira através de tudo que não é o indivíduo.',
    content: `     Por exemplo, a crença de que “se não fosse a crise eu estaria bem melhor financeiramente” ou “dinheiro causa muitos problemas” deixa claro que a pessoa atribui 100% dos seus resultados a outra coisa que não a si mesmo; bastante diferente de crenças como “eu não sei gerir dinheiro” ou “eu não consigo sair das dívidas”, que se relacionam totalmente com a pessoa e não à causas externas.
    A pessoa com muitas crenças sobre dinheiro no Nível de Ambiente deixa de lado a auto responsabilidade e tende, com muita frequência, a culpar tudo e todos pelos seus próprios resultados insatisfatórios. Pode responsabilizar o(a) parceiro(a), crise, o emprego, a renda atual, etc…mas com dificuldade, conseguem se lembrar de momentos em que assumiu a responsabilidade integral pelos resultados financeiros. Em casos mais extremos, podem adotar uma postura vitimista diante dos fatos, onde “o mundo conspira contra mim, e se não fosse por isso, eu estaria muito melhor”.
    Com frequência, a pessoa com o MMD enraizado nas crenças de Nível de Ambiente espera que “o mundo que me colocou nessa situação, também vai me tirar”, ou seja, normalmente essa pessoa aguarda que tudo ao seu redor melhore para ela finalmente se julgue capaz de resolver seus próprios problemas financeiros e quando não se resolvem, procura logo uma justificativa externa para isso.
    É muito comum a pessoa com um grau elevado de crenças no Nível de Ambiente adotar postura reativa diante dos acontecimentos e da própria vida, afinal “porque fazer algo, se é tudo ao meu redor que define meus resultados?”. Preferem esperar que as coisas aconteçam para só então se protegerem ou tomarem alguma outra atitude. Podem deixar para depois decisões importantes aguardando sempre “o melhor momento”, que muitas vezes, segundo a pessoa com muitas dessas crenças, “já passou”, se colocando muitas vezes como refém de um passado ideal e de um futuro incerto.
    Essa pessoa tende a pensar no dinheiro como uma mera consequência do meio externo e não como resultado um esforço intencional de si própria. Em outras palavras, para ela o dinheiro (ou a falta dele) é algo que simplesmente “acontece” graças à tudo a seu redor, cabendo para si, segundo suas crenças, pouca ou nenhuma responsabilidade.`,
    improvment: [
      'Assuma o Controle! Deixe o vitimismo de lado e comece a refletir: “Como eu, e somente eu, posso mudar meu resultado à partir de agora?”. Seria no mínimo tolo afirmar que as pessoas e circunstâncias ao seu redor não influenciam no seu resultado financeiro, mas quem define e carimba o sucesso ou o fracasso é você. Dez por cento vão acontecer os outros 90% quem define é você através da sua atitude.',
      'Você é o centro do seu Universo! Só existe uma pessoa em todo o planeta que produziu todos seus resultados: Você mesmo! Seu casamento, seu trabalho, sua renda, e até suas dívidas atuais são 100% fruto das suas escolhas. Pare de culpar os outros não importa o que tenha acontecido. Se você faz isso, por pior que tenha sido o que aconteceu, você pode estar retirando de si o real poder de mudar sua realidade financeira no rumo que desejar.',
    ],
    questions: [
      'Cem por cento das pessoas perderam dinheiro na crise? Ninguém ganhou muito dinheiro enxergando boas oportunidades?',
      'Quem são as pessoas com a maior segurança financeira: empresários que ganham dinheiro sem depender de um chefe, ou os assalariados?',
      'Tudo, tudo, tudo sempre dá errado na sua vida? Não houve um momento sequer em que algo deu muito certo?',
      'Todas as pessoas que são ricas, nasceram ricas?',
      'Se na crise é quando surgem as melhores oportunidades, porque alguns perdem muito dinheiro e outros ganham mais ainda?',
    ],
  },
  causaEfeito: {
    phrase:
      'Quem quer resolver um problema encontra uma solução, quem não quer, acha uma desculpa',
    abstract:
      'O nível de crença de Causa e Efeito agrupa as crenças que tratam o dinheiro como a causa que leva a efeitos negativos para a vida das pessoas.  Essas crenças vinculam a ideia de ganhar dinheiro como algo penoso ou que promove sofrimento. Em sua maioria, se assemelham à “justificativas” para que a pessoa não busque a prosperidade financeira.',
    content: `A pessoa com esse tipo de crença está sempre pronta para justificar o porquê de “não buscar intencionalmente a abundância financeira” ou de forma inconsciente, a razão “porque ela sempre se endivida”. Essa pessoa com muita frequência, associa o dinheiro à coisas ruins ou desagradáveis que acontecem com outras pessoas que, na mente dela, tem vários problemas decorrentes do dinheiro na conta bancária, de stress a problemas de saúde.
    Por acreditar que dinheiro acarreta coisas ruins, essa pessoa começa a evitar (muitas vezes inconscientemente) formas de ter sempre mais dinheiro, com a prerrogativa de que “ter muito dinheiro é chato, pesado ou desnecessário”
    Normalmente, a pessoa com muitas crenças no nível de Causa e Efeito busca uma desculpa para o insucesso financeiro e projeta no dinheiro motivos negativos que justifique não tê-lo. Com frequência acredita que o melhor momento para ter dinheiro de forma saudável e abundante não é agora, por diversos motivos como “não tenho tempo” ou “esse não é o melhor momento”.
    Outro comportamento bastante frequente neste tipo de pessoa é acreditar que se tiver mais dinheiro será muito desafiador administrar ou muito fácil perder. Para ela não faz sentido ganhar mais já que, provavelmente, algo acontecerá e a fará perder parte ou toda a quantia, apesar de o “todo o sacrifício” que teve para conseguir mais dinheiro.
    A dívida para esse tipo de pessoa surge, quando ela de forma muito intensa, começa a acreditar que o dinheiro traz tantas consequências negativas para a saúde mental, física, para os relacionamentos, etc, que começa a tomar ações, sem perceber, para evitar o dinheiro, mesmo gastando cada vez mais.
    Esse tipo de pessoa, com alto grau em crenças de Causa e Efeito, tendem a pensar no dinheiro isoladamente como algo ruim e que trás problemas. No entanto, quando esse mesmo dinheiro é utilizado para adquirir algo que lhe trás prazer momentâneo, ela tende a se esquecer completamente de que o mesmo dinheiro que ela assume trazer tantas consequências indesejáveis para as pessoas, também trás conforto e qualidade de vida.
    `,
    improvment: [
      'Ajuste seus pensamentos! Dinheiro trás diversão e torna as coisas mais fáceis. Basta observar como as pessoas que têm dinheiro para fazer o que querem se sentem. Pessoas que podem escolher onde querem comer, para onde vão viajar, que meio de locomoção querem usar, são pessoas que têm uma vida mais confortável e simples.',
      'A hora é agora! Os chineses dizem que “o melhor momento para se plantar uma árvore foi a 10 anos atrás, o segundo melhor momento é agora!”. Se você quer resultados diferente para seu futuro financeiro pare de se esconder por detrás de velhas justificativas como “não tenho tempo para aprender a gerir meu dinheiro”, “é difícil recomeçar”, “se eu ganhar mais eu vou perder mais” ou a pior, e mais comum de todas, “quando eu organizar minha vida financeira eu vou parar para aprender mais sobre como gerir meu dinheiro”. (Isso não faz nenhum sentido. Você precisa aprender a gerir o seu dinheiro para organizar sua vida financeira) Quando você quer começar a ter a vida do seu sonhos? Se a resposta for agora(!) comece também a agir diferente agora. Afinal, o melhor fermento para o dinheiro é o tempo! Quanto antes melhor.',
      'Dinheiro compra saúde! Quem tem mais condições de ter uma vida saudável, com acesso a bons profissionais da saúde e uma boa alimentação balanceada? São as pessoas que possuem dinheiro para pagar por tudo isso ou aquelas que estão nesse momento fazendo as contas para saber se vão ou não fechar o mês no vermelho? O stress, a irritação, problemas no relacionamento ou os problemas de saúde nunca surgem em função da sua busca para ter mais dinheiro, mas sim, por talvez você não saber lidar com as suas próprias emoções e pensamentos.',
    ],
    questions: [
      'Quem não sabe lidar com pouco dinheiro, saberá lidar quando tiver muito? Quando você contrai uma nova dívida você está mais perto ou mais longe de se livrar das dívidas?',
      'Hoje, com todas a experiência que você tem e os aprendizados, é mais fácil fazer certo ou continuar errando? Uma pessoa preparada comete mais ou menos erros?',
      'Se tudo está tão ruim agora, não seria agora justamente o momento certo para começar a mudar?',
      'As pessoas que mais adoecem são justamente as que têm condições financeiras de pagar por um acesso muito maior à coisas que promovem uma vida saudável?',
      'O que é mais fácil: Ganhar dinheiro quando você tem dinheiro ou quando você não tem dinheiro? Assim como pessoas aprender a ganhar dinheiro, elas também não podem aprender a gerir dinheiro?',
    ],
  },
  capacidade: {
    phrase: 'É capaz quem pensa que é capaz',
    abstract:
      'O nível de crença de Capacidade agrupa as crenças que consideram o dinheiro como algo que demanda habilidade para conseguir. Quando são limitantes, estas crenças partem da ideia de que a pessoa é pouco ou completamente incapaz de gerir ou ganhar dinheiro suficiente para atingir seus objetivos.',
    content: `A pessoa com crenças limitantes do nível de Capacidade normalmente considera a si como alguém incapaz de gerir seu dinheiro da forma correta, e com muita(!) frequência usa a dívida atual para provar sua incapacidade: “Tá vendo o tanto de dívida que eu tenho? Eu sou incapaz de cuidar das minhas finanças”.
    Em muitos casos, essa pessoa se diminui perante outras pessoas que ela considera que sabem gerir bem o dinheiro que têm. “Fulano consegue, eu não nasci pra isso”. Pensa no dinheiro como algo desafiador para conseguir e manter, e tende a acreditar que uma vida financeira abundante não é para ela.
    Com muita frequência, a pessoa com alto grau de crenças limitantes no nível de Capacidade acredita que “eu só consigo comprar alguma coisa mais cara se fizer uma parcela”, ou seja, uma dívida. Pois pensa que ter bens e comprá-los à vista não é para pessoas como ela, “incapazes” de ter a disciplina necessária para separar o dinheiro todos os meses e comprar o bem à vista, mesmo que isso custe um volume de juros gigantescos.
    Muitas vezes essa pessoa acredita que educação ou até uma formação superior é imprescindível para saber cuidar do dinheiro, quando na verdade não é. Ela acredita que somente pessoas bem “instruídas” e com um bom currículo são capazes de gerir bem o dinheiro. “A fulana ou o beltrano sabem cuidar do dinheiro porque fizeram uma faculdade.”
    É normal a pessoa com muitas crenças limitantes sobre dinheiro do nível de Capacidade evitar assuntos sobre dinheiro e finanças. Como ela parte da ideia de que é incapaz, logo qualquer esforço para aprender é inútil, energia gasta desnecessariamente, e portanto sente preguiça e prefere deixar para depois, o que pode agravar seriamente sua situação financeira e retirar da mesma ótimas oportunidades para ganhar dinheiro.
    Como essa pessoa acredita ser incapaz de conseguir e gerir dinheiro, é muito comum ela aguardar que um golpe de sorte mude sua vida. E mais, quando ela estende para outras pessoas a crenças limitantes de incapacidade como “ninguém consegue ter disciplina com o dinheiro” ela atribui à sorte as conquistas das outras pessoas: “Ele só conseguiu porque teve sorte!”.
    `,
    improvment: [
      'Dívida não prova sua incapacidade! O raciocínio de “tenho dívida logo sou incapaz de cuidar do meu dinheiro” está errado. O correto é “por acreditar que sou incapaz de cuidar do meu dinheiro estou endividado”. As dívidas não provam que você é incapaz, só provam que você acredita nesta incapacidade. Todos somos capazes de aprender a gerir nosso dinheiro. Você pode não ter a habilidade, mas como qualquer outra habilidade, pode ser desenvolvida. Assista com calma e atenção todos os vídeos do Ondazul. Esteja atenta(o) aos detalhes que você poderá desenvolver 90% da habilidade que precisa para, não só sair das dívidas, mas ser um excelente gestor das suas finanças.',
      'Currículo não faz diferença! Nem currículo, nem idade, sexo, cor ou formação acadêmica. Qualquer pessoa pode ser uma boa gestora de dinheiro, se acreditar que é capaz disso e buscar a informação necessária, que inclusive, não está em uma faculdade. Provavelmente você conhece pessoas que fizeram fortuna, apesar do pouco estudo. Na televisão e na internet está cheio de exemplos. Será que elas se tornaram boas gestoras das próprias finanças, se tornando inclusive ricas, acreditando que eram “péssimas gestoras do seu dinheiro”?',
      'Diga NÃO ao imediatismo! E ao senso comum… que está repleto de crenças coletivas que passam de pessoa para pessoa como: “ninguém junta dinheiro ou compra nada se não for parcelando em uma dívida. Ninguém tem disciplina pra juntar todos os meses e comprar à vista”. Enquanto você acreditar nisso, com toda certeza, não terá a disciplina necessária. A única(!) pessoa capaz de produzir resultados diferentes na sua vida, inclusive financeira, é você mesmo. Crie metas curtas para coisas mais simples e baratas que você deseja comprar, como um sapato ou celular novo. Estipule um prazo. Divida o valor do seu objetivo pelo prazo e separe esse valor da sua renda assim que você receber (este será seu primeiro “gasto” mensal). Ex: Celular R$ 1.000 daqui a 5 meses, ou seja, separar R$ 200 por mês. O valor ficou alto e você não consegue? Alongue mais o prazo ou reduza o valor do objetivo. Você pode até se perguntar: “Qual a diferença nisso? Eu posso simplesmente comprar agora e parcelar em 5 vezes e pagar os mesmos 200 reais por mês, com a diferença de que vou ter o bem e não precisar esperar.” No entanto, esse pensamento está tem te ajudando a sair das dívidas? Estas mesma forma de pensar, está evitando ou contribuindo para que seu estado de endividamento piore ainda mais? O segredo aqui não está na matemática, mas na mudança do padrão mental: De uma pessoa presa ao imediatismo e refém das dívidas para uma pessoa livre financeiramente e plenamente capaz de mudar o rumo financeiro de sua vida.',
    ],
    questions: [
      'Como as pessoas que não tiveram uma formação acadêmica conseguiram ter uma vida financeira abundante? Elas acreditavam que eram incapazes?',
      'Com você vai se sentir se não lutar para sair das dívidas? O fracasso acontece quando a gente não consegue, mas continua tentando, ou quando nós desistimos?',
      'Quando uma pessoa acumula dinheiro ela tem mais possibilidades de ter ainda mais dinheiro. O que acontece quando uma pessoa acumula dívidas...?',
      'Se você gasta o dinheiro que não tem, se você não espera o dinheiro acumular, quando você terá dinheiro para realizar seus sonhos?',
      'O planejamento e a disciplina torna as pessoas mais ou menos capazes de gerir o próprio dinheiro?',
    ],
  },
  valor: {
    phrase:
      'Valores e princípios fundamentais é tudo aquilo que de tão caro e precioso para você, não há dinheiro no mundo suficiente para comprá-los',
    abstract:
      'O nível de crença de Valor agrupa as crenças que relacionam o dinheiro diretamente com valores e princípios individuais extremamente importantes para o indivíduo. Quando algum tipo de crença limitante de Valor se instala, o indivíduo começa perceber o dinheiro como algo negativo e que corrompe seus princípios como honestidade, generosidade, humildade, justiça, etc..',
    content: `A pessoa com um alto grau de crenças limitantes no nível de Valor, com frequência acredita que o dinheiro é algo que corrompe pessoas e relacionamentos, ignorando o fato de que o dinheiro é um simples “pedaço de papel” e que, no máximo, ter dinheiro apenas potencializa quem o indivíduo já em essência.
    É comum a pessoa com esse tipo de crença acreditar que o “dinheiro é sujo” ou que “pessoas ricas são desonestas, ou ficaram ricas utilizando meios errados para isso”. Deste modo é como se essa pessoa acreditasse que o dinheiro é algo ruim e que portanto deve ser evitado, ao menos, evitado em quantidades superiores à simples subsistência das necessidades básicas.
    Como boa parte dos valores mais profundos se cristalizam até o início da pré adolescência, é bastante normal estas crenças limitantes terem sido instaladas por influência dos pais ou adultos que a pessoa vê como uma referência a ser seguida, ou eventualmente “negada”. Isso quer dizer que, se a pessoa tem uma referência positiva dos pais (ou quem desempenhou esse papel), essa pessoa vai buscar reforçar os valores ensinados, mas se a referência é negativa, a pessoa vai buscar exercer valores opostos. Por exemplo: a ação do pai que distanciou-se de casa para ganhar mais dinheiro tem a possibilidade de criar na criança a crença de que “dinheiro distancia as pessoas”.
    Como estes valores estão intimamente ligados à noção de caráter, é muito comum que para esta pessoa a busca por dinheiro desempenhe um papel negativo no caráter do indivíduo. Sendo o dinheiro em si (e não o próprio indivíduo) um agente que torne o ser humano ganancioso, egoísta, fútil ou desonesto.
    Outra consequência bastante comum desse tipo de crença limitante é fazer a pessoa acreditar que é “injusto ter dinheiro quanto tantos têm tão pouco” ou mesmo que “doar é melhor que receber”, fazendo com que, essa pessoa se sinta se torne alguém que se preocupa excessivamente com o outro e nada com ela mesma. Em alguns casos, essa pessoa evita inconscientemente o sucesso financeiro para que ela mesma não se torne, por si só, um meio de promover e injustiça social.`,
    improvment: [
      'Faça um favor a quem tem pouco… tenha MUITO dinheiro! Se seu desejo de tornar o mundo mais justo é sincero e verdadeiro, a melhor forma de ajudar as pessoas que têm menos que você e tendo mais que elas. Dinheiro amplia suas possibilidades, inclusive de fazer o bem que há dentro de você. Quanto mais possibilidade, maiores são as chances de você transformar a realidade das pessoas em volta de você e no lugar onde você vive. Afinal, ninguém pode dar aquilo que não tem. Como é dito no avião “em caso de emergência, máscaras de oxigênio cairão sobre sua cabeça, primeiro coloque-as em você depois em quem necessita”, e neste caso a lógica é simples: Se você não estiver bem, como você será capaz de ajudar a quem precisa? Lembre-se Quando várias pessoas estão em uma caverna escura e luz guia virá de alguém de fora desta caverna e nunca de dentro da escuridão.',
      'Dinheiro é uma lente de aumento! E como qualquer lente de aumento, é impossível mostrar o que não está ali. O dinheiro é incapaz de tornar alguém ganancioso ou generoso, desonesto ou de caráter, justo ou desumano, ele apenas amplia o que está dentro do coração e da mente desta pessoa. Dinheiro são possibilidades. E quando você tem possibilidades, se você é desonesto o dinheiro te trará novas possibilidades de fazer coisas ainda piores, mas se você é alguém generoso você terá ainda mais chances de mudar a vida de pessoas para melhor.',
      'O que é mais importante: dinheiro ou amor? O que é mais importante, sua perna esquerda o seu braço direito? AS DUAS COISAS SÃO FUNDAMENTAIS! Você não precisa escolher, porque as duas coisas não são incompatíveis. Elas podem (e devem) coexistir em abundância na sua vida. Entenda que essa comparação faz tanto sentido quanto dizer que “é melhor dar que receber”. Qual o sentido nessa crença? Se para alguém dar algo é fundamental que haja alguém recebendo algo! Ou seja, para que uma exista é necessário que a outra exista em mesma proporção. Sendo assim, lembre-se: Dinheiro é uma energia que promove possibilidades e consequentemente é compatível com qualquer outra coisa na sua vida, o significado quem dá é você!',
      'O dinheiro não tem vontade própria! Nenhuma consequência negativa pode vir de um simples pedaço de papel ou do que ele significa. A mesma faca que pode ser utilizada para fazer comida para quem tem fome, pode ser utilizada para matar uma pessoa. Repito: O dinheiro apenas reflete e amplia o que a pessoa já tem por dentro. ',
    ],
    questions: [
      'Se você tiver mais dinheiro, você é capaz de ajudar mais ou menos a quem precisa? O que já faz e faria ainda mais pelas pessoas, se você tivesse mais dinheiro que você tem hoje?',
      'Como um pedaço de papel pode corromper os valores de uma pessoa? Você sabia que as pessoas ricas são aquelas que mais fazem doações e apoiam instituições de caridade?',
      'Como você pode doar se não há alguém disposta a receber? Por esse motivo, como uma coisa pode ser mais importante que a outra?',
      'Nunca uma pessoa conseguiu abundância financeira sem passar pessoas para trás? Uma pessoa que tem pouco dinheiro também não pode ser gananciosa ou mesquinha?',
    ],
  },
  identidade: {
    phrase:
      'Ninguém pode ser escravo de sua identidade: quando surge uma possibilidade de mudança é preciso mudar.',
    abstract:
      'O nível de crença de Identidade agrupa as crenças que associam o dinheiro à identidade da pessoa, ou seja, à quem ela acredita ser enquanto indivíduo. Quando a crença de Identidade é limitante ela faz com que a pessoa não se sinta merecedora de uma vida financeira abundante, pois inconscientemente aquilo não faz parte de quem ela é.',
    content: `Junto com as crenças limitantes do nível de Espiritualidade, as crenças limitantes do nível de Identidade, talvez sejam aquelas que mais bloqueiam e travam a prosperidade financeira de alguém, pois elas se relacionam a quem a pessoa acredita ser, por tanto se essa pessoa acredita que “por ser mulher, nunca serei financeiramente próspera” ou “por ter nascido pobre nunca terei uma vida abundante” pouco pode ser feito.
    E o ponto é justamente esse: Nada ou muito pouco pode ser feito, pois não se pode mudar quem a pessoa é e se a crença limitante associa negativamente o dinheiro à própria identidade da pessoa, ela se considerará na maioria das vezes alguém impossibilitado de se tornar financeiramente próspero o que leva a um pensamento recorrente de “melhor nem tentar…”.
    É bastante frequente a pessoa com um alto grau de crenças limitantes sobre dinheiro no nível de Identidade se sentir desmotivada e sem energia para buscar novas formas de ganhar mais dinheiro ou sair definitivamente das dívidas, pois essa associação negativa do dinheiro com a identidade torna qualquer esforço na direção da prosperidade inútil, já que, como dito anteriormente, é incrivelmente desafiador mudar a sua própria identidade.
    Outro ponto muitíssimo comum e a pessoa se considerar inconscientemente não merecedora do sucesso financeiro. O que faz muito sentido, pois ter dinheiro em abundância para realizar todos os sonhos dessa pessoa é o mesmo que negar quem ela acredita ser, e seu distanciamento do dinheiro se torna cada vez mais crônico.
    Esta pessoa tende a se contentar com o pouco que tem e apegar-se à escassez, fazendo da sua atual realidade financeira um espécie de cárcere privado ou prisão invisível onde a própria pessoa enxerga grades (inexistentes!) que a impedem de ter uma vida financeiramente abundante e próspera.
    `,
    improvment: [
      'Ter dinheiro é uma questão de atitude! Na verdade, mais alguns fatores como, criatividade, determinação, foco e disciplina. Mas com certeza, idade, sexo, cor, raça ou opção sexual não são fatores determinantes para que alguém tenha ou não sucesso financeiro na vida. A menos que você acredite do fundo do seu coração que  “sou velho demais para ganhar dinheiro”, “sendo mulher, se quiser uma vida financeira estável, meu marido precisará ganhar bem” ou “por ter a cor da pele que tenho não terei nunca as mesmas chances de outras pessoas para prosperar financeiramente”. Claro que se você quiser pode, continuar acreditando em tudo isso e usando quem “você é” para justificar seus fracassos financeiros ou abandonar  estas crenças limitantes e começar agora a sair da sua prisão imaginária.',
      'Se faça merecedor! Ganhar dinheiro é uma questão sim de merecimento. As pessoas que se tornam bem sucedidas encontraram uma forma de ganhar dinheiro e consequentemente se fizeram merecedoras. Não tem nada haver com quem elas são, mas sim, o que elas fazem, como pensam, sentem e principalmente no que elas acreditam.',
      'Conheça te a ti mesmo! Nada pode ser mais certeiro que conhecer melhor sobre si. É impossível atingir o sucesso financeiro sem acreditar no seu próprio potencial de construir abundância. Busque treinamentos de autoconhecimento, pois ele é capaz de fazer com que você perceba quem você realmente é(!) e não quem você acredita ser. Todo ser humano é dotado de capacidades ilimitadas e, portanto, pode se desenvolver em qualquer área, mas o primeiro passo é acreditar que é capaz, caso contrário será uma batalha perdida. Os seus resultados financeiros atuais não provam quem você é, eles dizem somente que “até agora, suas estratégias atuais, sua forma de pensar, sentir e agir” não vão trazer os resultados que você deseja”.',
    ],
    questions: [
      'Pessoas ricas podem fazer o que quiserem, quando quiserem, do jeito que quiserem. Podem proporcionar os melhores momentos pra quem elas ama. Se adoecerem podem ser tratadas nos melhores locais. Têm a possibilidade de ajudar milhares de pessoas. O que é felicidade?',
      'Quantas mulheres se tornaram bem sucedidas por conta própria? No que elas acreditavam? Será que acreditavam serem incapazes ou não merecedoras do sucesso financeiro?',
      'Por ser mais velho(a), você possui muito mais experiência que pessoas mais jovens. Como você pode usar isso para ter uma vida financeira abundante? Será que sua situação financeira atual é fruto da sua idade ou de muito tempo fazendo as coisas do mesmo jeito?',
      'Todas as pessoas do planeta que ganharam dinheiro nasceram ricas? O que as outras que não nasceram ricas fizeram para adquirir riqueza? Como elas pensam? No que elas acreditam?',
      'Como você pode se tornar merecedor de abundância? Por qual motivo você sente peso na consciência quando compra algo que gosta? Se você soubesse exatamente o quanto pode gastar com cada coisa, ainda assim se sentiria culpado por gastar o valor correto?',
    ],
  },
  pertencimento: {
    phrase:
      'Ser e pertencer são coisas inseparáveis. Pois conectar-se ao outro não é uma simples necessidade é um princípio da existência',
    abstract:
      'O nível de crença de Pertencimento agrupa as crenças que associam o dinheiro aos relacionamentos pessoais nas suas mais diversas formas. Quando limitantes, estas crenças podem influenciar negativamente em hábitos de consumo desenfreados e na busca constante da aprovação alheia.',
    content: `Enquanto as crenças de Identidade se relacionam ao indivíduo e como ele se percebe enquanto pessoa, as crenças do nível de Pertencimento se associam aos círculos dos quais a pessoa faz parte. País, cidade, trabalho, amigos e família são alguns dos diferentes círculos de identificação que uma pessoa faz parte.
    Crenças como “para o brasileiro, liberdade financeira é algo praticamente impossível” ou “vindo de onde vim, da família que faço parte, é impossível ter sucesso financeiro”, são exemplos de crenças limitantes do nível de Pertencimento.
    A questão é, quando uma pessoa associa negativamente o dinheiro aos grupos dos quais faz parte, é incrivelmente difícil mudar sua realidade financeira, por um simples motivo: Por mais desafiador que seja, uma pessoa pode mudar a si mesma, mas é impossível deixar de “ter nascido na família que nasceu ou de ter crescido no país que cresceu”. Não se apaga o passado.
    Isso faz com que a pessoa com um alto grau de crenças limitantes no nível de Pertencimento se torne alguém desmotivado, com poucas esperanças e que de certa forma, aceita sua realidade financeira pacientemente, apesar de todo o desconforto, já que “vindo de onde vim, não há muito o que eu possa fazer”. Pode acontecer de, em alguns momentos, essa pessoa se revoltar contra sua situação financeira atual culpando a sociedade ou o mercado pela “injusta” situação em que se encontra.
    Eu muitos casos, para se sentir pertencente à um grupo, a pessoa busca se assemelhar com as pessoas desse grupo, ainda que isso custe financeiramente (e emocionalmente) muito caro. Seja no padrão de se vestir, nos lugares em que frequenta ou no carro que anda, a pessoa com crenças limitantes do nível de Pertencimento busca com frequência a validação da opinião do outro sobre si mesma. Preocupando constantemente com a imagem que outras pessoas terá dela. O que com frequência, faz com que ela desenvolva uma necessidade contínua de provar-se merecedora para as outras pessoas com as quais ela convive. Especialmente pai e mãe.
    Aqui entra o nível mais profundo de pertencimento: as referências paterna e materna. Normalmente, quando nesse nível, as crenças limitantes de Pertencimento podem ser inconscientes. Embora haja vários casos onde a pessoa acredita conscientemente que “eu preciso provar para meus pais que consegui prosperar financeiramente” ou “é minha obrigação dar orgulho para meus pais” em muitos outros, crenças limitantes como “não é certo ganhar mais que meus pais” ou “no fundo vou acabar como meus pais” impedem silenciosamente o sucesso financeiro da pessoa.
    Isso faz com que a pessoa, em alguns casos, comece a se sentir incompleta ou até mesmo injustiçada pela vida, culpando as circunstâncias do seu passado pelos resultado do presente. Por outro lado, há uma ansiedade recorrente nesta pessoa, em se fazer notada pelas outras pessoas que ela enxerga relevantes em sua vida.`,
    improvment: [
      'Não se apaga o passado... mas se escreve um futuro! Tudo que aconteceu com você no seu passado, influenciou e continuará influenciado a sua vida. Somente um tolo desconsideraria o passado para entender o presente. Mas(!) o seu futuro é escrito a cada ação que você toma no presente. Se você continuar olhando para trás para definir onde você “pode” chegar, continuará repetindo a mesma história da sua vida em ciclos, mas se decidir projetar suas ações, tomando como referência onde você quer chegar na vida, você tem enormes chances de atingir seus objetivos. Lembre: Traga do passado somente as lições e aprendizados, e deixe tudo que te limitou, onde nunca deveria ter saído… no passado!',
      'Qual a sua imagem perante você?! Somos seres coletivos. Isso quer dizer que obviamente buscamos conviver com diferentes pessoas de diferentes círculos de relacionamento, e mais, nos assemelhando às pessoas com as quais nos identificamos. Porém, se você não tem a real dimensão de quem você é da sua verdadeira essência, pode ser que você gaste muito tempo e dinheiro tentando se parecer em aparência e estilo de vida com pessoas que no final não são tão importantes assim na sua vida. As pessoas que mais importam são aquelas com as quais você se conecta de coração para coração e não aquelas que te apreciam ou respeitam pela roupa que você veste, pelo carro que você anda ou pela casa onde você mora. Ah! Vale lembrar que essas pessoas que olham para o que você tem, não pagam seus boletos nem estão te ajudando a sair das dívidas.',
      'Basta de vitimismo! O que aconteceria com as pessoas que venceram na vida se acreditassem que “elas nunca vencerei porque a sociedade foi injusta comigo” ou que “nunca terei sucesso financeiro porque nasci numa família pobre”? Elas continuariam vivendo em escassez, culpando a vida, o mercado, a sociedade… enquanto perdiam o maior poder do ser humano: a auto responsabilidade. Olhe para o que você é capaz se acreditar diferente. Perceba que com criatividade e dedicação você pode encontrar uma forma de mudar por completo sua vida financeira, não importa de que família você vem, em que lugar você nasceu ou cresceu.  Mas se partir da ideia de que não a nada para fazer, realmente, não haverá mais nada a ser feito.',
    ],
    questions: [
      'O que você quer provar para as pessoas? Quem são as pessoas? O que você ganhará provando? O que virá depois que você tiver provado à todos? Se você não precisasse provar nada, como seria?',
      'Todas as pessoas que ganharam dinheiro no Brasil, nasceram ricas ou encontraram um jeito de se tornarem?',
      'Quais ensinamentos dos seus pais podem fazer com que você consiga abundância financeira?',
      'Quem são as pessoas que se importam com você e quem são as que se importam com quem você aparenta ser?',
    ],
  },
  espiritualidade: {
    phrase:
      'Se você vivencia sua espiritualidade, só pode esperar o melhor da vida, pois ou usufrui o sucesso ou aprende alguma coisa. É simples assim.',
    abstract:
      'O nível de crença de Espiritualidade agrupa as crenças que associam o dinheiro àquilo que está além da vida do ser humano e dos seus relacionamentos. Pode ou não estar associada à religiosidade. Quando limitantes, estas crenças podem influenciar negativamente fazendo a pessoa acreditar que sua realidade financeira é fruto do destino ou da vontade de algo superior.',
    content: `Dentro dos dogmas e ensinamentos religiosos é possível encontrar diversos momentos onde se Faexalta a pobreza e a humildade no sentido financeiro da palavra, enquanto por outro lado, em diversos momentos o dinheiro é tido como algo relacionado ao pecado, à avareza, à ganância e como um caminho mundano que retira o homem do caminho da espiritualidade.
    Isso cria um terreno fértil para que a pessoa construa em sua mente, um conjunto de crenças limitantes sobre dinheiro no nível de Espiritualidade, fazendo com que a pobreza seja justificada e de certa forma incentivada pelos ensinamentos religiosos. E por se tratar da “vontade um ser superior”, a pessoa que possui esse tipo de crença se resigna diante da sua própria situação financeira, aceitando a escassez financeira em suas vidas como sendo “da vontade de Deus”.
    Não importa como seja chamado, Deus, Universo ou destino, se há a influência onipotente de uma força maior a pessoa aceita a própria realidade e desiste de buscar uma vida financeiramente abundante, pois frequentemente acredita que este é o seu destino.
    É normal a pessoa com um alto grau de crenças limitantes no nível de Espiritualidade ter julgamentos negativos sobre pessoas ricas e bem sucedidas. Com justificativas como “fulano conseguiu porque passou por cima de muita gente” ou “ficou rica porque é gananciosa”.
    É bastante comum também, a pessoa com esse tipo de crença limitante menosprezar a riqueza material em vida, com a justificativa de que “quando morrer não levará nada”. Faz uso desse tipo frase, com frequência, para justificar ou minimizar o fato de não ter abundância financeira em vida. Ao mesmo tempo, pode acontecer da pessoa se colocar numa posição passiva, à espera da “misericórdia ou providência divina” que solucionará, em definitivo, seus problemas financeiros.
    `,
    improvment: [
      'Faça tua parte que eu te ajudarei! Não existem acasos no Universo! Tudo que você conquista é fruto daquilo que você planta, tanto a abundância quanto à escassez. Observe seus resultados atuais e você entenderá o que tem plantado. O Ser Maior que você acredita pode te dar melhores condições para colher aquilo que você planta, mas é impossível colher o que você não plantou!',
      'Dinheiro é uma lente de aumento! E como qualquer lente de aumento, é impossível mostrar o que não está ali. O dinheiro é incapaz de tornar alguém ganancioso ou generoso, desonesto ou de caráter, justo ou desumano, ele apenas amplia o que está dentro do coração e da mente desta pessoa. Dinheiro são possibilidades. E quando você tem possibilidades, se você é desonesto o dinheiro te trará novas possibilidades de fazer coisas ainda piores, mas se você é alguém generoso você terá ainda mais chances de mudar a vida de pessoas para melhor.',
      'Ricos não levam nada… nem você! De fato quando morremos não levamos nada. Tudo fica para aqueles que estão vivos. Inclusive o legado que você construiu, o bem que você fez através do bom uso do dinheiro que vocês conseguiu em vida. Lembre-se que se você tiver abundância financeira você não levará nada, e se você viver uma vida de escassez também não levará nada! Pare de justificar sua falta de dinheiro com frases do tipo “caixão não tem gaveta, quando morremos não levamos nada”. Isso só te limita e te faz contentar-se com os problemas financeiros que tem hoje e terá no futuro seu não se permitir pensar diferente.',
    ],
    questions: [
      'O que um pedaço de papel (dinheiro) é capaz de fazer sem um ser humano por trás? Será que o dinheiro transforma as pessoas ou faz as pessoas se revelarem como realmente são?',
      'Por qual motivo ter mais dinheiro distância o homem da espiritualidade? O dinheiro “obriga” o ser humano à isso ou ele decide por conta própria se distanciar?',
      'Será que a escassez conecta a pessoas à Deus? Você se torna mais capaz de ajudar as outras pessoas estando mal ou bem financeiramente?',
      'O que acontecerá se a chuva cair e você não tiver plantado nada? O que você quer para os próximos anos e que depende exclusivamente de você começar?',
      'O que você pode fazer de diferente para ter sucesso financeiro no futuro próximo? De que forma você pode honrar a Deus obtendo abundância financeira?',
    ],
  },
  conjuntural: {
    phrase: '',
    abstract: '',
    content: `Dívidas conjunturais surgem de um pensamento bastante comum: “Não preciso me preocupar com o futuro”. Muitas podem ser suas motivações. Desde uma esperança cega de que nada precisa ser feito pois tudo dará certo, até uma insegurança negligente de quem evita preocupar-se com o próprio orçamento, para não descobrir a dimensão do problema que já sabe que tem.
    A pessoa com esse padrão de pensamento busca não pensar no futuro, ou quando pensa, evita tudo que pode causar algum desconforto. É bastante comum pessoas com esse Modelo Mental sobre Dinheiro passarem por períodos no azul, mas têm a sensação de que “sempre acontece algo que as jogam no vermelho”.
    De todos os Modelos Mentais sobre Dinheiro, os endividados conjunturais são aqueles que mais se vêem como vítimas da própria situação financeira. A culpa é sempre de alguma coisa que acontece e pega você desprevenido, em outras palavras, é desafiador para você assumir a responsabilidade por não ter se planejado. Muitos endividados conjunturais acreditam ser bons gestores do próprio dinheiro atribuem a tudo e a todos as motivações das dívidas que contraíram. Responsabilizam a crise, o(a) companheiro(a), o trabalho, a renda que ganha, os políticos… Não importa, sempre há uma razão para terem se endividado mais uma vez e essa “razão” nunca a sua própria falta de planejamento para se proteger das eventualidades que sempre surgem. Afinal:
    “Se há uma constante no orçamento de qualquer pessoa, são as eventualidades”
    Endividados conjunturais costumam se complicar financeiramente e muitas vezes de forma recorrente com os gastos eventuais, isto é, com aqueles gastos que normalmente as pessoas não tem todos os meses, como IPVA, Seguro do Carro, uma troca repentina de celular, ou um grave problema da saúde. Grande ou pequena, as eventualidades fazem com que suas finanças saiam do eixo e normalmente acabe se endividando.`,
    improvment: [
      'Assuma a responsabilidade! Não importa o que aconteceu e que fez com que você se endividasse. Busque a sua parcela de responsabilidade e reflita sempre sobre o que você pode fazer para se prevenir contra as eventualidades.',
      'Planeje-se para gastos eventuais! Ter um plano de saúde, uma reserva para gastos eventuais, ou um dinheiro guardado para caso você perca sua renda, pode ser a saída para nunca mais se endividar.',
      'Elimine as justificativas da sua vida! Problemas acontecem, mas você é o único responsável por todos os sucessos e desafios que já teve, que tem e que terá na vida. A boa notícia é que quando você toma para si a responsabilidade você assume todo o poder para dar o rumo que quiser para sua vida.',
    ],
    questions: [],
  },
  estrutural: {
    phrase: '',
    abstract: '',
    content: `As Dívidas Estruturais, diferentemente das Conjunturais e de Oportunidade, surgem decorrente de uma sucessão de pequenos hábitos, comportamentos e desculpas que a pessoa que padece desse tipo de dívida se conta. Enquanto as Dívidas Conjunturais e de Oportunidade surgem mediante grandes eventualidades, como a decisão de comprar um carro, comprar uma casa de forma equivocada ou até mesmo um problema de saúde grave, as Dívidas Estruturais são fruto um padrão de consumo desenfreado, refletido em decisões impensadas de compra e motivado por questões emocionais bastante profundas.
    A sensação de quem possui esse tipo de dívida é de nunca parecer ter realmente saído dela. Como se a dívida fizesse parte da estrutura orçamentária desde sempre - daí seu nome. Não existe um fato isolado que possa ser lembrado como “o grande causador de todo o desequilíbrio financeiro”, pelo menos não na forma de um “gasto” específico. E este é o ponto.
    Normalmente, este comportamento desregrado de consumo é desencadeado em função de uma dor emocional aguda, seja ela ocasionada por relacionamentos ruins, por uma insatisfação contínua com o trabalho ou local onde vive ou pela chamada identificação externa. Um processo onde a pessoa, para se sentir pertencente ao grupo com qual convive, incorpora itens de consumo no seu orçamento que não condizem com a renda da própria pessoa. A pessoa que sofre desse MMD de Identificação Externa começa a atribuir aos itens que compra a sua própria identidade, de tal forma que abrir mão de uma roupa de marca ou do celular do ano, faz com que esta pessoa não se sinta pertencente ao grupo social do qual faz parte, deixando de reconhecer a si própria.
    Normalmente as Dívidas Estruturais surgem motivadas por desejos bastante distintos: 1) Sanar uma dor, um vazio ou carência emocional ou 2) financiar a vaidade e um estilo de vida próximo ao das pessoas com as quais a pessoa se identifica. E apesar de serem duas coisas distintas ambos os desejos podem estar presentes ao mesmo tempo e mutuamente se influenciando. Como por exemplo, alguém que está insatisfeito no trabalho, pois o que ganha não é suficiente para financiar, sem dívidas, o mesmo padrão de vida das pessoas com as quais essa pessoa convive ou gostaria de conviver.
    Para identificar se a pessoa possui o MMD que promove as Dívidas Estruturais, é fundamental estar atento e consciente das desculpas que essa pessoa se conta no ato de consumir alguma coisa que ela sabe que não deveria. Podemos destacar três bastantes comuns. A primeira é a desculpa “oportunista” (não confundir com as Dívidas de Oportunidade), fácil de ser percebida em padrões de linguagem como: “Vou aproveitar que eu… e vou comprar…”.
    A segunda é a desculpa de “merecimento” observada em padrões de linguagem como: “Vou comprar porque eu mereço…”. Aqui cabe uma pequena observação. Não se trata do fato da pessoa merecer ou não aquele item que ela tanto quer, e sim disso ser usado para justificar de forma recorrente uma ação que vai causar o total descontrole financeiro. Essa se torna uma desculpa fajuta, pois não há margem para qualquer contestação lógica, afinal como comprovar racionalmente que uma pessoa merece ou não comprar o último modelo do iPhone? Talvez ela simplesmente queira, não possa ou não deva comprar naquele momento por questões financeiras, mas merecimento é absolutamente subjetivo e carece de embasamento concreto, menos para aquele que se julga merecedor.
    Por fim, a terceira desculpa bastante comum, especialmente na pessoa que possui o Modelo Mental de Dinheiro de Identificação Externa, é justamente a de “identificação externa” que aparece em padrões de linguagem como: “O(A) fulano(a) tem, sendo assim eu também vou comprar…”. Para este caso é muitíssimo comum a pessoa não perceber que está utilizando esse tipo de justificativa, mas para identificar esse padrão, basta observar se a pessoa que usa esse tipo de desculpa busca estar sempre parecendo ter o mesmo padrão de roupas, frequentar os mesmos restaurantes ou fazer as mesmas viagens que as pessoas do seu meio de convivência.
    É comum a pessoa com Dívidas Estruturais tentar recorrer à ferramentas de controle de gastos com a expectativa de ajustarem suas finanças. Baixa aplicativos, faz planilhas ou utiliza a velha caderneta de gastos, tudo acreditando que se souber “o que já foi”, vai conseguir evitar novos gastos e consequentemente o descontrole. Mas aqui, vale o velho ditado:
    “Águas passadas não movem moinhos”.
    Analisar os gastos passados não é a resposta. É o mesmo, para uma pessoa com dor de dente, anotar todos os doces que comeu ao longo do último mês e esperar que isso elimine a cárie.
    Outra coisa bastante recorrente é a quantidade grande de diferentes financiamentos que uma pessoa com Dívida Estrutural tem. Normalmente essas dívidas são fruto de tentativas fracassadas de resolver o sintoma e não a causa, ou seja, a pessoa contrai uma dívida para resolver outra, ou para evitar uma pior, tudo de forma recorrente para tapar o buraco no orçamento. No mês seguinte, lá está a nova dívida, as velhas dívidas, os mesmos gastos de sempre, e os antigos comportamentos que de fato levaram ao endividamento.
    Quanto mais frustrada essa pessoa se sente, o que é ainda mais agravado pela decadência financeira em que se encontra, mais ela coloca seu foco em algo que deseja ter, consumir, comprar, para amenizar o desconforto emocional e a dor que sente por não ter o relacionamento que gostaria, por não estar no trabalho que ama ou não se identificar com quem gostaria de ser. `,
    improvment: [
      'Autoconhecimento! Nada pode ser mais efetivo para você que sofre de dívidas estruturais que se conhecer melhor. Entender mais sobre suas emoções, seus pensamentos e suas crenças. O que você realmente gosta de fazer? Que tipo de relacionamentos você deseja ter? Quem você realmente é? Entender mais sobre você mesmo, dará todas as ferramentas necessárias para transformar sua vida e colher os resultados que você verdadeiramente deseja.',
      'Mude o mindset! A mesma cabeça que te colocou no problema, dificilmente vai ter as respostas para te tirar dele. Evite ao máximo contrair uma nova dívida para resolver antigos problemas. Busque o auxílio de um Planejador Financeiro REAL que vai saber conduzir não só as questões comportamentais, mas também saberá te orientar sobre o que fazer para resolver suas dívidas do presente de uma vez por todas.',
      'Cuidado com o crédito! De modo geral, a pessoa com Dívidas Estruturais tem um desafio a mais em saber lidar com créditos disponibilizados pelo cartão ou bancos. Se você já tem várias dívidas decorrentes de consumo, provavelmente contrata crédito rápido e que consequentemente tem taxas elevadas de juros. A melhor saída, até que você resolva as questões emocionais, é reduzir ao máximo o limite do seu cartão de crédito e, se possível, eliminar junto ao seu banco a possibilidade de contratação de cheque especial, LIS, etc.',
    ],
    questions: [],
  },
  oportunidade: {
    phrase: '',
    abstract: '',
    content: `“Eu quero! Eu quero, quero agora e vou buscar uma forma de me convencer de que posso agora!” Essa seria a mensagem se fosse possível ligar uma “tecla SAP” na mente de pessoas que possuem um Modelo Mental sobre Dinheiro que produz as chamadas Dívidas de Oportunidade. Essas pessoas têm duas características em comum: Imediatismo e a fantasia de um futuro perfeito.
    Se por um lado essa imprudência otimista cria cenários onde tudo dará certo num futuro próximo, não importa quais sejam suas ações no presente, por outro lado, o imediatismo aje como o combustível perfeito para endividar rapidamente as pessoas, que fazem da irresponsabilidade com as finanças uma regra.
    As Dívidas de Oportunidade são o produto final da pessoa que não pode deixar passar “uma grande oportunidade”. Quando vê um financiamento travestido de “pegar ou largar”, não pensa duas vezes. Descontos são sua maior isca e para essa pessoa, os famosos gatilhos de escassez (“vai acabar”) e urgência (“vai acabar agora”) do marketing funcionam com perfeição.
    É importante ressaltar que diferente da pessoa que acaba produzindo Dívidas Conjunturais, por esperar que nada de mal acontecerá, a pessoa cujo resultado são as Dívidas de Oportunidade, age na certeza de que no futuro tudo será melhor. Enquanto um é reativo o outro faz da proatividade sua lei. Apesar dessa sutil diferença, em ambos os casos há uma total negligência do impacto dos gastos eventuais (aqueles que não ocorrem todos os meses). Nunca estão preparados pois tendem a não se lembrar ignorar a existência desse tipo de gasto.
    Outra característica da pessoa que tem Dívida de Oportunidade é o excesso de auto confiança. Em muitos casos, essa pessoa costuma acreditar que não importa o quanto ela se comprometa financeiramente, ela sempre encontrará (ou encontrarão por ela) um jeito de reduzir o impacto do endividamento e organizar suas finanças.
    Quando essa pessoa se encontra diante da decisão de trocar o carro por um outro (muito) acima do próprio padrão de renda, ou de trocar o pequeno apartamento por uma casa maior, ela normalmente encontra uma série de justificativas que não se sustentam por muito tempo como: “Vou reduzir todos os meus gastos na semana que vem”, “Vou para de comer fora e levar comida todos os dias”, “Vou começar a ganhar bem mais daqui a um mês, e todos os meus outros gastos não vão aumentar”...
    `,
    improvment: [
      'Imagine que você está andando em uma direção com um objetivo na sua frente, e por algum motivo, você decide colocar um elástico te puxando na direção contrária. Faz algum sentido acreditar que será mais fácil correr em direção ao seu objetivo agora que você tem alguma coisa te puxando para trás? Em outras palavras, acreditar que você vai conseguir enxugar seus gastos agora que tem gastos ainda maiores é no mínimo ilógico. ',
      'Tenha um orçamento mapeado! É fundamental que você tenha a exata dimensão do seu orçamento mensal, inclusive considerando a mensalização dos gastos eventuais (o somatório de todos os seus gastos eventuais do ano dividido por 12). Essa visão real do seu orçamento trará a resposta para uma pergunta fundamental: Isso realmente cabe no meu orçamento?',
      'Esteja consciente e atento! Gatilhos de urgência e escassez são utilizados o tempo todo pelo marketing para apanhar consumidores como você que se deixam levar por essas frases como “só hoje” e “últimas unidades disponíveis”. Se tem uma coisa que é abundante e constante são boas oportunidades para quem tem dinheiro investido. São essas pessoas que realmente obtêm as melhores e verdadeiras oportunidades. Do contrário você estará apenas contraindo uma nova dívida.',
      'Espere 48 horas! Quando você sentir aquele desejo “incontrolável” para comprar algo que parece uma “grande oportunidade” e sua mente começar a tentar te convencer de que aquilo precisa ser feito naquele momento, repita a frase em voz alta para você mesmo: “Obrigado, mas sou mais forte que meus impulsos. Se for importante volto em 48 horas”. Esse tempo é mais que necessário para que os impulsos emocionais disparados diante da “oportunidade” se dissipem e você possa então, tomar essa decisão de forma racional e com o devido planejamento do seu orçamento.',
      'Por que eu não deveria? Essa pergunta é poderosa! Quando estiver diante de uma “grande oportunidade”, encontre três respostas às seguintes perguntas: Por que eu devo comprar isso? E em seguida, com muita consciência e compromisso com seu orçamento e futuro, encontre mais três respostas para a pergunta: Porque eu não devo comprar isso? Não importa quais são. Busque os 3 primeiros motivos que vier à sua mente para não gastar seu dinheiro naquele momento. Tome nota, reflita sobre essa decisão comparando todas as respostas. Vale realmente a pena?',
      `Peça ajuda! Com certeza você conhece alguém que você reconhece como centrado e ponderado, e que também é financeiramente equilibrado. Se você está desejando muito comprar algo e ao mesmo tempo sente que precisa de muitas justificativas para comprar naquele momento, busque a opinião dessa pessoa. Conte mais sobre o produto e responda duas perguntas para essa pessoa antes de tomar sua decisão: Porque eu devo comprar isso? Porque eu não devo comprar isso?
A vida é um equilíbrio constante entre dever, querer e poder.  Porque nem tudo que eu quero eu posso, nem tudo que eu posso eu devo e nem tudo que eu devo eu quero. `,
    ],
    questions: [],
  },
  esperanca: {
    phrase: '',
    abstract: '',
    content: `Parece uma contradição, mas a esperança em algumas culturas, inclusive na Grécia Antiga era vista como algo negativo, chegando em algumas histórias ser considerada o pior dos males da humanidade. Esperança vem do verbo esperar, em outras palavras, esperança é a característica de quem espera. Aquela pessoa que permanece alheia a tudo que acontece ao seu redor na expectativa de que tudo dará certo mesmo que não faça nada, ou muito pouco, por isso.
    Pessoas que são motivadas pela esperança e que tomam grande parte das suas decisões sob influência desse Modelo Mental sobre Dinheiro, acreditam que não importa o que aconteça, tudo dará certo, preferem deixar para depois o problema acreditando que tudo se encaixará perfeitamente. Como se uma força maior guardasse para sua vida, não importa o que aconteça, uma vida próspera e abundante, cabendo à ela simplesmente esperar.
    É importante não confundir com o Modelo Mental sobre Dinheiro Otimista, pois enquanto o otimista age com a crença de que tudo dará certo, o esperançoso, aguarda, espera que tudo aconteça positivamente. Nas finanças, o otimista ignora a possibilidade de um negócio dar errado ou trazer prejuízo, o esperançoso toma pequenas decisões no dia à dia esperando que tudo se encaminhe bem. O otimista é proativo enquanto o esperançoso é reativo.
    Pessoas com esse MMD preferem não se preocupar com as eventualidades financeiras que surgem durante o caminho. Vivem suas vidas alheias à consequência de não se planejarem. Apesar de não necessariamente acreditarem que algo muito bom vai acontecer e salvá-las dos problemas e das dívidas (como os otimistas), simplesmente não se preocupam, pois não há nada do qual precisam ser salvas, pois para pessoas com o MMD esperançoso, no futuro misteriosamente dará tudo certo. No relacionamento são aquelas que dizem para o(a) parceiro(a): “Relaxa! Vai dar tudo certo!”.
    A fé é força dos esperançosos. São pessoas que tranquilizam e passam a certeza para os que estão próximos de que tudo vai funcionar muito bem. Ao contrário de pessoas pessimistas, é natural que muitos queiram a companhia dos esperançosos, pois eles estão sempre prontos para dar uma palavra positiva e confiante. Mas as pessoas com o MMD esperançoso, deve sempre estar atenta à dose de esperança nas suas tomadas de decisão, pois:
    “ O que separa o remédio e o veneno é a dose”
    `,
    improvment: [
      'Busque agir para se proteger! Eventualidades financeiras surgem sempre. Crie uma reserva financeira para quando algo acontecer você esteja preparado, como uma redução da renda, uma demissão, um problema de saúde ou simplesmente aquelas despesas que sempre acontecem em algum momento bem definido, como presentes de Natal, aniversário, IPVA e o seguro do seu carro.',
      'Torne tangíveis seus sonhos! Faça uma lista dos seus sonhos. Defina data para que eles aconteçam e o valor necessário. Subdivida esse prazo em períodos menores com metas e ações para cada fase. Monte um plano de ação sobre o que você precisa fazer para atingir seus objetivos menores e que te levarão a realizar seu grande sonho.',
      'Se quiser uma vida financeiramente livre, aja! Não deixe para depois a resolução dos seus problemas que podem surgir. Você não precisa se transformar em alguém paranóico e pessimista, mas estar preparado para as eventualidades aumentam ainda mais as chances de você ter um futuro feliz e sem dívidas.',
    ],
    questions: [],
  },
  reatividade: {
    phrase: '',
    abstract: '',
    content: `Os reativos são os bombeiros dos endividados: Sempre aguardam o incêndio acontecer para se proteger das chamas. Diferente dos esperançosos que aguardam o desfecho positivo do filme chamado vida, os Reativos não são movidos por uma crença de que tudo vai dar certo, eles simplesmente tendem a não se moverem por coisas que ainda não aconteceram.
    Pessoas com esse modelo mental, até se preocupam com o futuro e sabem que problemas financeiros podem acontecer, eles têm ciência das eventualidades, mas preferem deixar para se ocuparem do problema de amanhã, amanhã. Tendem a procrastinar ao máximo decisões financeiras para não terem que lidar com preocupações futuras. Muitas vezes sentem que o assunto cuidar do dinheiro é chato e pode ser deixado para outro momento.
    Para os Reativos é um grande desafio olhar para o futuro e traçar metas nessa direção. Por esse motivo, comumente, sentem-se frustrados por não conseguirem atingir seus objetivos financeiros. Estão com muita frequência num estado de ansiedade elevado, pois os problemas atingem os reativos sempre “com as calças nas mãos”.
    Assuntos como a viagem do ano que vem, a futura reforma da casa, um possível problema de saúde futuro, são motivos de contrariação para os reativos. Falar de aposentadoria então… “Quando chegar lá eu vejo o que faço”.
    Apesar de muitas vezes se verem como exímios solucionadores de problemas, pessoas com um MMD Reativo, têm a tendência de “quando o problema surge” resolverem o mais rápido possível e sem qualquer tipo de planejamento, o que em muitos casos, pode agravar ainda mais a situação financeira.
    `,
    improvment: [
      'Seguro, seu melhor amigo! Se você deseja uma vida tranquila, financeiramente abundante e livre das dívidas, prepare-se para as eventualidades. Monte o seu orçamento para encaixar um plano de saúde, um seguro para seu carro e se fizer sentido, um seguro de vida para proteger as pessoas que você ama.',
      'Faça uma BPL! Base de Plena Liquidez, é um montante de dinheiro que você acumula com o objetivo de te proteger de uma perda repentina de renda, assim como um cinto de segurança que você coloca, mas o objetivo é não fazer uso do cinto. Isso impedirá que você se endivide sempre que você perder, ou diminuir, sua renda.',
      'Se quiser uma vida financeiramente livre, aja! Não deixe para depois a resolução dos seus problemas que podem surgir. Você não precisa se transformar em alguém paranóico ou pessimista, mas estar preparado para as eventualidades aumentam ainda mais as chances de você ter um futuro feliz e sem dívidas.',
    ],
    questions: [],
  },
  inseguranca: {
    phrase: '',
    abstract: '',
    content: `O Modelo Mental sobre Dinheiro Inseguro é o MMD do avestruz: “Melhor não ver o que está por vir”. Ou seja, para resolver o problema a melhor forma é se “esconder” do problema, muito semelhante naqueles momentos em que para ”esconder” uma criança, basta tapar os olhos dela. Pessoas com esse Modelo Mental com frequência acreditam que o assunto dinheiro é algo penoso e que causa desconforto.
    Diferente do MMD Negligente, onde a pessoa ignora a existência do problema, com recorrência o MMD Inseguro prefere agir ignorando, não os problemas financeiros em si, mas sim as consequências destes problemas. Para os Inseguros, a melhor forma de resolver um problema é fingindo que ele não existe ao ter que lidar com a responsabilidade de enfrentá-lo e buscar solução. Isso acontece em função do grande incômodo que sentem ao tomar consciência da sua situação financeira atual, sendo assim, o melhor a se fazer é tapar os olhos e não fazer nada.
    Os Inseguros normalmente preferem não se envolver com as próprias finanças, ou quando o fazem, evitam focar no que pode acontecer de negativo futuramente em relação às finanças, preferem viver o aqui e o agora, como uma forma de fugir do que está por vir.
    Normalmente o Inseguro diz: “prefiro nem ver minha conta bancária” ou “não vou olhar a fatura do meu cartão…”. Esse padrão de pensamento traduzido em falas como essa, são uma das principais causas do endividamento do Inseguro. De modo diferente do MMD Esperançoso ou do Otimista que partem de um auto engano evidente, o MMD Inseguro tem a plena consciência de que “algo não vai bem” com suas finanças, mas escolhe conviver com insegurança de não saber ao certo como ou o que vai coloca-lo numa dívida sem fim.
    Às vezes é confundido com uma pessoa despreocupada e tranquila, quando o assunto é dinheiro, já que raramente se deixa levar por esse tema. No fundo sente que cuidar do dinheiro e planejar suas finanças, antevendo situações financeiramente ruins, é chato e desagradável. Em alguns casos, para os Inseguros, planejamento é até doloroso, pois quando as dívidas já saltam aos olhos e não podem mais serem ignoradas, pensar em eventualidades financeiras lhes causa um profundo desconforto.
    `,
    improvment: [
      'Acenda a luz! Se você estiver sendo perseguido por um cachorro raivoso, dentro da sua casa, na mais completa escuridão, provavelmente a primeira coisa que você vai tentar fazer é acender a luz. Talvez você já saiba que ignorar um problema financeiro não o resolve, mas na realidade, o aumenta… e muito! Estar preparado para as eventualidades financeiras reduzem ou até eliminam o impacto negativo no seu orçamento, como no caso de uma redução da renda (demissões, queda nas vendas…) ou de um aumento repentino dos gastos (problemas de saúde, seguro do carro, um notebook que estraga). ',
      'Comece agora! Defina um dia com data e hora marcada para mapear todos os seus gastos do presente, dívidas atuais e seus recebimentos. Talvez o que você verá pode incomodar, causar desconforto, mas será o primeiro (e mais importante) passo para sua segurança financeira. Se faltar alguma informação, busque no dia seguinte, mas comece já!',
      'Aproveite seu futuro! O seu ponto forte é aproveitar o presente e isso é primordial, mas lembre-se que melhor ainda é poder ter prazer no presente com a segurança de um futuro planejado, aliás, essa é a definição mais precisa de: Qualidade de vida!',
    ],
    questions: [],
  },
  carenciaEmocional: {
    phrase: '',
    abstract: '',
    content: `A frase “se você quer resultados diferentes, precisa agir diferente”, já se tornou parte do conhecimento de boa parte das pessoas. No entanto ela já se tornou tão óbvia quanto “se você quer ferver a água você precisa acender o fogão” e de certa forma, perde o sentido quando dita isoladamente. Nossas ações não surgem do nada produzindo resultados aleatórios. As ações que as pessoas tomam são motivadas por sentimentos e emoções, e o que muitas vezes parece ser uma decisão racional e planejada, no final das contas não passa uma decisão impulsionada por algum tipo de emoção, da alegria à tristeza, da ansiedade à vaidade.
    O Carente Emocional, é um indivíduo que sente-se incompleto em algum aspecto emocional. Não se trata de alguém que “sente falta de carinho”, na verdade o sentido é muito mais amplo. É uma pessoa que normalmente tem relacionamentos desajustados em alguma área, podendo ser no âmbito familiar, social ou conjugal.
    Relacionamentos conflituosos, ausência de afeto, quebra de expectativas, quando associadas ao consumo como “analgésico”, fazem do Carente Emocional uma pessoa com uma alta probabilidade de contrair Dívidas Estruturais, ou seja, de fazer as dívidas normalmente parte do orçamento.
    Para o Carente Emocional, os gastos para preencher o vazio causado pela falta de amor, afeto, amizade ou mesmo carinho, se tornam parte inseparável do orçamento e com muita frequência utilizam a desculpa do “Eu mereço”, para justificar gastos, que no fundo sente que não devia fazer naquele momento. O que tem um efeito devastador nas finanças e no psicológico da pessoa.
    No cérebro, os neurotransmissores são acionados exatamente como nos usuários de drogas. Sob efeito da carência emocional (abstinência), na maioria das vezes de forma inconsciente, decide comprar algo para tapar esse vazio (droga). No ato da compra, sente uma sensação de bem-estar e prazer (efeito da droga) que naquele momento trás um alívio momentâneo para a “dor” que sente.
       No entanto, esse efeito é passageiro. Assim que o efeito causado pelo consumo passa, os problemas nos relacionamentos continuam os mesmos, mas agora soma-se o agravamento da situação financeira, a frustração ao ver que os problemas são os mesmos, e autopunição por ter feito o que não deveria ter feito. Como se houvesse uma voz mais madura dizendo dentro da cabeça do Carente Emocional: “Você não devia ter feito isso… de novo”.
    `,
    improvment: [
      'Resolva o problema! E pare de dar 100% da sua atenção ao sintoma. A dívida é somente parte do sintoma. Para sair do endividamento definitivamente o primeiro passo é você buscar mudar aquilo que está ruim nos seus relacionamentos familiares e conjugais. Pois bons sentimentos e emoções levam a ações melhores e que produzem resultados INCRÍVEIS! Busque cursos na área do conhecimento e inteligência emocional. Se for casada(o) entre em contato com a equipe da ONDAZUL para te orientar em como ter um relacionamento dos seus sonhos.',
      'Corte o Cartão temporariamente! Para todos os outros 8 tipos de Modelo Mental sobre Dinheiro a recomendação é oposta, ou seja, mantenha o cartão de crédito. Mas no seu caso, procure eliminar as formas de crédito rápido, e nada é mais rápido que o cartão de crédito. Isso não trata o problema, mas impede você de aumentá-lo ainda mais e um momento de crise. ',
      'Esteja atento(a)! Muita atenção às justificativas que você se conta para comprar coisas que sente que não deve naquele momento, especialmente as do tipo “Eu mereço…”. Sim! Você merece, mas também merece uma vida realizada em todos os aspectos, financeiro e pessoal. O prazer daquela compra pode até animar por algumas horas, mas passa. Quando seu cérebro vier com a história do “Você merece” mentalize a seguinte frase: “Obrigado por me dizer. Eu realmente mereço, mas agora quero algo ainda maior. UMA VIDA MELHOR!”',
    ],
    questions: [],
  },
  insatisfacaoPessoal: {
    phrase: '',
    abstract: '',
    content: `Enquanto o MMD Carente Emocional está muito mais conectado à desafios em relacionamentos familiares e conjugais o MMD Insatisfeito Pessoal tem como base problemas relacionados à carreira, trabalho e missão de vida. A pessoa com esse Modelo Mental sobre Dinheiro, com frequência, sente-se incompleta no seu trabalho ou carreira, seja por não gostar do que faz ou por tentar se encaixar numa atividade que não possui qualquer aptidão.
    Por sermos seres coletivos, todas as pessoas foram feitas de alguma forma para servir. O trabalho é a expressão do ato de servir: Entregar algo que você sabe ou colocar alguma habilidade que você tem à serviço de outras pessoas. Quando isso não acontece, diversos sentimentos surgem, indo desde uma indisposição para as atividades de rotina ou um sentimento de frustração, até coisas mais sérias como a sensação constante de estar perdido na vida, inclusive podendo chegar, em casos mais graves, à depressão.
    “Peixes são incríveis nadadores. Macacos são os melhores em subir em árvores. Coloque um peixe para subir uma árvore e você terá criado um ser que se julgará incapaz pelo resto da vida.”
    Inclusive, o mesmo pode acontecer com pessoas que estão desempregadas, afinal, de alguma forma elas não estão contribuindo, nem servindo outras pessoas que precisam das suas habilidades, que é exatamente o mesmo incômodo de pessoas que estão empregadas em trabalhos que não gostam ou não se identificam.
    Esse desarranjo e vazio gerado pela insatisfação pessoal, uma vez associado ao consumo, pode ser catastrófico para as finanças deste indivíduo. Especialmente nos momentos mais difíceis no trabalho, quando ocorrem discussões com chefes, com clientes, resultados abaixo das expectativas. A pessoa com esse Modelo Mental sobre Dinheiro tende a buscar outras formas de ter prazer. Essa busca pode vir na forma de gastos recorrentes mais elevados como um aumento nos gastos com alimentação fora de casa e aos finais de semana, ou pode vir na forma de compras de artigos mais caros como sapatos, roupas, eletrônicos, etc.
    A busca por prazer a qualquer preço se torna cada vez maior e quando a receita não acompanha, entra em cena a mistura explosiva: as desculpas e o crédito rápido. A associação das justificativas do tipo “Eu mereço” e “Vou aproveitar que vou comprar isso e vou comprar aquilo também..” somadas à possibilidade do cartão de crédito ou do cheque especial, é um terreno fértil para produzir um endividamento que nunca se acaba.`,
    improvment: [
      'Seja o peixe que nada! Provavelmente você perderá muito tempo tentando se encaixar ou ganhar dinheiro com um atividade que não gosta. Busque descobrir, caso você ainda não saiba, o que você realmente gosta e é bom em fazer. Ganhar dinheiro com o que você se destaca, com aquilo que te dá tesão em fazer, é muito mais fácil, mesmo que leve um tempo, do que com algo em que você passa a semana esperando chegar as 18 horas de sexta-feira. Sucesso financeiro é consequência do seu sucesso pessoal e não o contrário.',
      'Foco no lugar certo! O vazio que você talvez sinta por não estar satisfeito com o seu trabalho ou carreira, não será preenchido com consumo desenfreado e gastos excessivos com supérfluos. A solução definitiva para seu endividamento reside em passar a se dedicar ao que você realmente gosta de fazer. Use sua criatividade para encontrar um jeito de fazer dinheiro com isso e finalmente montar um planejamento claro, com metas e datas bem definidas para deixar o trabalho que você não gosta e começar a servir as pessoas com o seu melhor.',
      'Esteja atento(a)! Muita atenção às justificativas que você se conta para comprar coisas que sente que não deve naquele momento, especialmente as do tipo “Eu mereço…”. Sim! Você merece, mas também merece uma vida realizada em todos os aspectos, financeiro e pessoal. O prazer daquela compra pode até animar por algumas horas, mas passa. Quando seu cérebro vier com a história do “Você merece” mentalize a seguinte frase: “Obrigado por me dizer. Eu realmente mereço, mas agora quero algo ainda maior. UMA VIDA MELHOR!”',
    ],
    questions: [],
  },
  identificacaoExterna: {
    phrase: '',
    abstract: '',
    content: `Identidade e pertencimento. Em outras palavras, quem sou eu e a quem (ou a qual grupo) eu pertenço?  Essas duas coisas são de altíssima importância na vida de qualquer pessoa. Saber quem você é de verdade e sentir-se pertencente aos grupos que você faz parte como família, amigos e no ambiente de trabalho são fundamentais para que qualquer pessoa se sinta realizada na vida... inclusive financeira.
    “O que diferencia o remédio e o veneno é a dose.”
    A pessoa com esse Modelo Mental sobre Dinheiro de Identidade Externa, de forma exagerada, projeta na validação do outro a construção da sua identidade, em outras palavras, é uma pessoa que precisa do “ok” das pessoas do meio em que convive para definir sua própria imagem. Quando essa necessidade, de fazer parte, de ser aceito por certos grupos, que normalmente se traduz num desejo de “ser bem visto”, se conecta à um padrão elevado de gastos, acima da renda atual, surgem todos os componentes para criação da Dívida Estrutural.
    É muito comum a pessoa com o MMD de Identidade Externa ignorar a dimensão dos seus gastos flexíveis e eventuais, ou seja, todos os gastos que não possuem uma data fixa para acontecer todos os meses são deixados de lado quando essa pessoa decide comprar algo que fatalmente vai endividá-la. É normal a pessoa com esse MMD recorrer à desculpas do tipo “tudo bem, é só dessa vez...”, “se eu não tiver isso, como vão me ver…?” ou “se eu recusar o convite por falta de $ o que vão pensar?”.
    Essa insegurança faz com que a pessoa com o MMD de Identidade Externa comece a se confundir cada vez mais entre quem ela realmente é e quem ela quer aparentar ser. Ela se distancia do seu verdadeiro eu para viver uma vida baseada na aprovação das pessoas com quem ela convive ou gostaria de conviver.
    O dinheiro, para essa pessoa, passa a ser um instrumento para financiar sua vaidade, que por muitas vezes se esconde atrás da máscara da necessidade ou do “merecimento”. Essa pessoa, normalmente tem suas ações e decisões de gastos impulsionadas pelo desejo de provar que merece ser aceita pelos grupos dos quais quer se sentir parte.
    O sentimento mais comum quando compra algo para saciar esse desejo é de êxtase e bem-estar, misturados com uma sensação de “agora está tudo certo”. No entanto, diferente dos outros MMDs Carente Emocional e Insatisfeito Pessoal, o de Identificação Externa demora a sentir a necessidade de um novo item para preencher o vazio.
    Porém, com o tempo, acontece de surgir a sensação de se sentir uma “fraude”, afinal, aparentar um alto padrão de vida não condiz com uma vida endividada, e o esforço para negar esse estado de endividamento faz a pessoa retomar o ciclo e contrair ainda mais dívidas para financiar “as aparências”.
    “Orgulho e ego são as duas coisas mais caras já criadas“
    A pessoa com o MMD de Identidade Externa, mais que qualquer outro MMD, tende a confundir qualidade de vida com padrão de vida, ou seja, para ela, ter uma vida boa é o mesmo que ter uma vida a ser invejada.`,
    improvment: [
      'Primeiro o mais importante! Não tem nada de errado em gostar de artigos mais refinados ou um estilo de vida caro e requintado, o grande problema é se endividar para tê-los enquanto você esquece de que você não é o que você tem. Se você perdesse todos os bens materiais que você possui hoje, quem você seria? De quais grupos você continuaria fazendo parte? ',
      'Você não precisa provar nada! As pessoas que realmente fazem importância na sua vida se conectam à você pela pessoa que você é e não pelos bens que você possui. Uma vida de aparências só te distancia cada vez mais da sua essência, dos seus verdadeiros sonhos e do seu futuro financeiramente livre. Lembre-se de que os grandes bilionários do planeta não se preocupam em mostrar o quanto, nem o que possuem. Vivem uma vida, sem dúvida confortável e num alto padrão, mas de forma discreta e sem estardalhaço.',
      'Orgulho e Ego custam muito caro! Muito mais caro inclusive que ter uma vida realmente abundante financeiramente. Esconder as aparências custa muito caro e pode nesse exato momento estar destruindo seus sonhos, suas viagens, o futuro dos seus filhos ou a sua aposentadoria.',
    ],
    questions: [],
  },
  negligencia: {
    phrase: '',
    abstract: '',
    content: `“O pior cego é aquele que não quer ver”. Essa frase do senso comum das pessoas se aplica muito à aquelas que tem o Modelo Mental sobre Dinheiro Negligente.
    Por definição, esta pessoa tem como característica o ato de ignorar, deixar de lado, preferir não se envolver, com o seu orçamento. Com frequência, ignora a real dimensão dos seus gastos mensais e anuais, quando possui dívidas prefere não ver, e nos casos em que a renda oscila, decide por não considerar isso nas decisões de compra.
    Suas justificativas para aproveitar “uma oportunidade imperdível” muitas vezes se apoiam em frases como “vou reduzir meus gastos a partir de amanhã”, quando sequer sabe verdadeiramente o quanto gasta com cada item do orçamento. A pessoa com o MMD Negligente tende a viver como coadjuvante da própria vida, isto é, se envolvendo o mínimo possível quando o assunto são finanças, embasando suas crenças de que no futuro tudo vai dar certo.
    Diferente do MMD Inseguro que ignora não o problema, mas a consequência negativa do mesmo, a pessoa com o MMD Negligente ignora a existência do problema financeiro em si. E de forma semelhante ao MMD Inseguro, acredita que estará melhor se continuar assim: alheia às consequências de não assumir as rédeas do seu dinheiro.
    É comum a pessoa com esse MMD achar o tema “ gerir dinheiro” algo chato e desnecessário. Vive o presente como se não houvesse amanhã e normalmente, quando está diante de uma “grande oportunidade” ou da “promoção imperdivel”, se preocupa muito mais com o limite disponível do cartão, que com os gastos que virão eventualmente, como o seguro do carro, o celular que é roubado ou a queda da renda por um problema de saúde.
    Por ignorar o real estado das suas finanças, a pessoa com MMD Negligente utiliza com muita frequência o auto engano para justificar suas decisões. Sempre que não encontra uma justificativa apoiada num orçamento saudável como, “Eu vou comprar agora, pois tenho o dinheiro e já estou preparado para todos os meus gastos, inclusive os eventuais”, a pessoa com o MMD Negligente se conta todo tipo de história, que justifique fazer aquele gasto naquele momento. Por exemplo: “Eu vou comprar, porque a partir de amanhã, não como mais fora de casa. Nenhum dia!” ou “Vou comprar agora e a partir de segunda, vou para o trabalho todos os dias de carona.”
    `,
    improvment: [
      'Chega de Histórias! Se você já se contou algum tipo de desculpa para comprar algo que suas finanças não permitiam naquele momento, como “a partir de hoje eu vou economizar” e duas semanas depois (no máximo) voltou com os velhos hábitos, mas agora com a nova dívida, pare agora! Se você não conseguiu manter sua promessa para você por pelo menos  3 meses, você não fará isso à partir de agora, nem da próxima segunda-feira. Dê um passo de cada vez. Muitas pessoas se contam justificativas, como uma criança que tenta convencer os pais de que merece “aquele presente de aniversário”, fazendo um monte de promessas de auto sacrifício ou de dedicação que nunca tiveram. Pense: Em algum outro momento surgirá um novo desejo… você precisará se contar uma nova justificativa?',
      'Hora de acender a luz! O melhor momento para tomar consciência do seu orçamento é agora. Acesse o sistema Ondazul e descubra quanto custa de verdade seu custo de vida. Se você tem uma grande quantidade de gastos comprometidos você tem uma pequena autonomia para reduzir seus gastos no curto prazo. Verifique através do Ondazul, sempre que for fazer um novo gasto maior, para saber se você não vai se complicar ainda mais. E se precisar de ajuda nas contas, procure um especialista da REAL para te ajudar nessa decisão tão importante.',
      'Nada é mais seguro que a realidade! Você é capaz sim de aumentar sua capacidade de gerar cada vez mais dinheiro, basta você usar sua criatividade, mudar certos comportamentos e crenças, e começar a agir de forma diferente do que você vem agindo. Mas  lembre-se: o dinheiro que você pode ganhar um dia ainda não está na sua conta. Evite novos compromissos financeiros contando com o dinheiro que você ainda não ganhou. Compre o que você deseja com o dinheiro que você tem, e não com base no dinheiro que você pode ter. Essa expectativa, além de te custar muito caro, pode frustrar você e causar outros problemas emocionais e psicológicos que vão te impedir, inclusive, de ganhar mais dinheiro!',
    ],
    questions: [],
  },
  impulsividade: {
    phrase: '',
    abstract: '',
    content: `“Quero, e quero agora!” Nada reflete melhor o Modelo Mental Impulsivo que esta frase. O nome deste modelo mental é a característica mais marcante da pessoa que têm este modelo: A impulsividade.
    Quando deseja algo e esse desejo se instala em sua mente é muito difícil (quase impossível) fazer esta pessoa mudar de ideia. Provavelmente ela vai encontrar todo tipo de justificativa para comprar o que deseja, na hora em que decide e se não encontrar, simplesmente se apegará à qualquer desculpa e comprará assim mesmo. Isso acontece pois, diferente dos outros dois Modelos Mentais sobre Dinheiro que produzem dívidas de Oportunidade (Otimista e Negligente), para o MMD Impulsivo o auto engano não é uma regra, simplesmente pelo fato de que quando ele quer ele faz.
    Em tese, esse tipo de comportamento proativo e que muitas vezes pode vir junto com uma aparente autoconfiança, esconde um desejo de não confrontar com as próprias limitações e desafios. Para a pessoa com o MMD Impulsivo, normalmente torna-se mais doloroso que para as demais, tomar consciência de suas próprias limitações financeiras. Aceitar que “não pode comprar, porque não tem dinheiro”, para a pessoa com esse MMD é ainda mais desafiador.
    A pessoa com o MMD Impulsivo, mesmo que não compre algo que deseja muito naquele momento, continua com isso na mente, em muitos caso, até que decida de uma vez por todas adquirir o que tanto quer. Aliás, isso desencadeia outro traço bastante comum na pessoa com o MMD Impulsivo: a ansiedade que é disparada diante de uma decisão de compra que a pessoa julga importante.
    Os gatilhos utilizados pelo marketing de urgência e escassez tem um impacto ainda mais forte na pessoa com o MMD Impulsivo, pois de todos os MMDs, o Impulsivo é aquele que toma a decisão mais rápido, pois ele depende muito menos de justificativas racionais, como a situação atual do orçamento, se tem ou não dinheiro na conta ou se aquele item vai endivida-lo ainda mais.
    É bastante comum a pessoa com o MMD Impulsivo desconhecer seus desejos e sonhos de longo prazo, e quando conhece, costuma não vincular a realização destes sonhos à decisões melhores sobre dinheiro no presente, ou seja, toma as decisões no presente, impulsionada por um desejo imediato e sem perceber (ou se importar) com os seus grandes sonhos no futuro.
    `,
    improvment: [
      'Controle suas emoções! A chave mestra para solucionar seu estado de endividamento de uma vez por todas, é controlar os impulsos e as emoções que fazem com que você cometa os erros que comete. Quando se deparar com “uma grande oportunidade” ou se ver tomado pelo desejo incontrolável de consumir algo, desvie o foco para o próprio sentimento. Por que você deseja tanto aquilo naquele momento? Como seria se tivesse “aquilo” daqui a seis meses? Que sonhos você estará abrindo mão se optar por comprar aquilo naquele momento?',
      'Tenha sonhos irresistíveis! O ser humano é guiado por duas forças muito poderosas, fugir da dor e buscar prazer. Se você não possuir sonhos que te façam dizer os “nãos” necessários no presente, será um desafio ainda maior evitar o prazer imediato e se manter com o orçamento saudável. Essa é uma das diferenças fundamentais entre investidores e pessoas endividadas: um motivo forte o suficiente para agirem pensando no futuro.',
      'Não pense duas vezes! Pense quatro! Oportunidades sempre surgem para pessoas que têm condições reais de assumirem compromissos financeiros sem se endividarem. Se você toma uma decisão impensada e se complica financeiramente você definitivamente não poderá aproveitar as verdadeiras oportunidades que surgirem. Sendo assim, antes de tomar decisões por impulso vá para casa, espere 24 horas e ligue para algum amigo ou familiar que você admira, é financeiramente equilibrado e tem resultados semelhantes aos que você deseja para sua vida. Pergunte a ele o que ele acha da sua decisão, diga porque você quer tanto aquilo e porque está em dúvida se deve ou não comprar. Após essa conversa, espere por mais 48 horas e reflita sobre o que ouviu. Isso é suficiente para que você tome uma decisão mais racional e não 100% tomado pelo impulso da emoção.',
    ],
    questions: [],
  },
  otimismo: {
    phrase: '',
    abstract: '',
    content: `O MMD Otimista esconde uma armadilha silenciosa: a ideia de que o pensamento positivo é o único responsável pelo resultado de uma pessoa. E nesse caso a Lei Universal “o que diferencia o remédio do veneno é a dose” é determinante.
    O otimismo em excesso transforma pensamento positivo em irresponsabilidade, sonho em ilusão e faz com que a pessoa com o MMD Otimista se endivide num nível elevadíssimo. Isso acontece, pois, com muita frequência, essa pessoa toma decisões no presente, confiando num recebimento futuro, que muitas vezes, é menos que uma possibilidade, apenas uma expectativa ou uma simples desejo.
    Mas é fundamental que seja feita uma ponderação: o otimismo de forma saudável faz com que uma pessoa acredite ser capaz de realizar seus objetivos à partir dos recursos que já tem no presente. Na dose certa, o otimismo motiva e leva à ação. Na dose errada faz com que a pessoa tome decisões impensadas com base numa ilusão de grandeza ou de um futuro utópico.
    A pessoa com o MMD Otimista, com frequência recorre à desculpas e justificativas como “eu me viro e consigo pagar”, “vai dar tudo certo no meu negócio e em seis meses vou ter um lucro incrível e conseguirei pagar essa dívida de agora.” ou “eu vou ser promovido no ano que vem e vou conseguir pagar…”. Por mais que essas coisas sejam todas desejáveis e possíveis, elas ainda estão no campo da possibilidade motivando um endividamento concreto. A renda é uma hipótese, a dívida é real. Quando essas hipóteses não se concretizam, normalmente existem duas possibilidades: 1) a pessoa é tomada por um profundo sentimento de frustração, 2) a pessoa não suporta a frustração da realidade e busca alimentar ainda mais o otimismo, que passa a funcionar com um analgésico.
    Com recorrência, a pessoa com o MMD Otimista tem como características um sentimento de autoconfiança inabalável e uma confiança nos resultados financeiros futuros inquestionável, tão inquestionável que as vezes, se esforça para espantar da mente qualquer tipo de dúvida que surja em função de alguma decisão que está prestes a tomar.
    A pessoa otimista em excesso tende a se incomodar com qualquer coisa ou circunstância que teste sua fé inabalável no futuro perfeito. Frequentemente, ela age com base em expectativas positivas e não em fatos concretos. Compra contando com o dinheiro que ainda não recebeu. E pequenas doses de sucesso tendem a reforçar ainda mais sua fé num futuro promissor, assumindo compromissos e endividamentos ainda maiores.
    `,
    improvment: [
      'Pé no chão! O que ainda não aconteceu não é realidade, apenas uma mera expectativa. Se você continua tomando decisões baseadas no seu excesso de otimismo é mais provável que você se distancie do seu verdadeiro objetivo. Trace o seu planejamento e siga à risca todas as ações que você definir. O presente ao final será a realização dos seus objetivos, o sentimento de dever cumprido, de auto realização, de maturidade, e ao mesmo tempo, suas finanças em perfeito estado!',
      'Sem desculpas! Não deixe sua mente te convencer de que você pode o que não deve! Quando perceber que você está se justificando para comprar algo que surgiu à sua frente na forma de uma “excelente oportunidade”, repita para si mesmo: “Obrigado, mas eu escolho viver a realidade e tomar decisões melhores. Meu futuro começa agora com as ações certas!”. Vire as costas e se presenteie por tomar uma decisão financeira correta. Algo simples, como algo que você goste de comer ou um tempo de qualidade para fazer algo que goste. Mas se aquilo for realmente tão importante, inclua no seu planejamento na forma de uma meta ou um sonho, defina as ações para atingir e quando tiver condições financeiras reais compre.',
      'Renda presente, gasto futuro! Inverta a sua lógica de raciocínio. Ao invés de tomar suas decisões de consumo no presente com base na sua renda futura, isto é, aquela que você ainda não tem, faça o oposto, projete os seus gastos futuros (trocar de carro, viajar, etc…) e faça as contas com base na renda atual. Você já paga as parcelas das dívidas, logo você também é capaz de separar, quando estiverem quitadas, a mesma quantia de dinheiro para realizar seus sonhos futuros sem dívidas. Lembre-se, o segredo do seu sucesso financeiro é poupar a renda do presente para realizar seus gastos no futuro, e não gastar hoje a renda que você acha que terá no futuro!',
    ],
    questions: [],
  },
};

export default laudos;
